import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useStateValue } from "./context/StateProvider"; // Import the useStateValue hook from your state context

import { getUser } from "./services/api";
import NotFoundPage from "./pages/error";
import InvoicePage from "./pages/invoice";
import Landing from "./components/Landing Page/Landing";
import FAQPage from "./components/Faq/FAQPage";

import Confirm from "./components/Booking Flow/Verify/Confirm/Confirm";
import Status from "./components/Dashboard/Booking Status/Status";
import Slot from "./components/Booking Flow/Verify/Details/Slot.jsx";
import Navbar from "./components/Navbar/Navbar";
import Aboutus from "./components/About us/Aboutus";
import Loader from "./components/loader/loader";
import SelectLocation from "./components/Booking Flow/SelectLocation/SelectLocation";
import Service from "./pages/service";
import AddCardSuccess from "./components/payment/success.jsx";
import AddCardFailure from "./components/payment/failure.jsx";
// import AddCard from "./components/payment/card.jsx";
import ProfilePage from "./components/Dashboard/profile/profile.jsx";

export default function Router() {
  const [{ user, token, isLoading,pickup, dest, }] =
    useStateValue(); // Destructure user and token from the state
  const navigate = useNavigate(); // Access the navigate function from React Router
  const location = useLocation(); // Access the current location object from React Router

  // Check if user and token exist, and redirect accordingly
  const shouldRedirectToAuth = !(user && user?.isProfileCompleted && token);
  const shoudlShowSlots = pickup && dest;
  const protectedPaths = ["/book/confirm", "/book/rides"];

  // Function to check if the token is valid
  const isTokenValid = async (token) => {
    try {
      // Call the API to check token validit

      const response = await getUser("", localStorage.getItem("token"));

      // If the API returns a successful response, set the token as vali
      if (response.status === 200 && response.data.isProfileCompleted) {
        localStorage.setItem("user", JSON.stringify(response.data));
      } else {
        if (protectedPaths.some((path) => location.pathname.startsWith(path))) {
          navigate("/auth", { replace: true });
        }
        localStorage.removeItem("user");

        localStorage.removeItem("token");
      }
    } catch (error) {
      if (
        shouldRedirectToAuth &&
        protectedPaths.some((path) => location.pathname.startsWith(path))
      ) {
        navigate("/auth", { replace: true });
      }
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
  };

  // Perform the redirect based on the state
  useEffect(() => {
    isTokenValid();
    if (
      shouldRedirectToAuth &&
      protectedPaths.some((path) => location.pathname.startsWith(path))
    ) {
      navigate("/auth", { replace: true });
    } else if (!shoudlShowSlots && location.pathname.includes("/book/slot")) {
      navigate("/");
    }
  }, [navigate, location.pathname, shouldRedirectToAuth]);
  const shouldRenderNavbar = !location.pathname.includes("/receipt");
  return (
    <>
      {isLoading ? (
        <>
          {shouldRenderNavbar && <Navbar isLoggedIn={!shouldRedirectToAuth} />}
          <Loader />
        </>
      ) : (
        <>
          {shouldRenderNavbar && <Navbar isLoggedIn={!shouldRedirectToAuth} />}
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/book" element={<SelectLocation />} />
            <Route path="/about" element={<Aboutus />} />
            <Route path="/contact" element={<FAQPage />} />
        

            {shoudlShowSlots && (
              <>
                <Route path="/book/slot" element={<Slot />} />
              </>
            )}

            {/* booking routes */}
            {!shouldRedirectToAuth && (
              <>
                <Route path="/book/confirm" element={<Confirm />} />
                <Route path="/book/rides" element={<Status />} />
              </>
            )}

            {/* auth routes */}
   
           {/* service route */}
            <Route path="/service" element={<Service />} />
            {/* Invoice route */}
            <Route path="/receipt/:id" element={<InvoicePage />} />

            {/* card success */}
            <Route path="/card/success" element={<AddCardSuccess />} />
              {/* carad failure */}
            <Route path="/card/failure" element={<AddCardFailure />} />
            {/* card Add */}
            {/* <Route path="/card/add" element={<AddCard />} /> */}

            <Route path="/profile" element={<ProfilePage />} />


            {/* Error page for unmatched routes */}
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>
        </>
      )}
    </>
  );
}
