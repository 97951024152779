import { fetchUser, fetchToken } from "../services/fetchLocalStorage.js";

const userInfo = fetchUser();
const accesstoken = fetchToken();

export const initialState = {
  user: userInfo,
  token: accesstoken,
  isLoading: false,
  isAuthenticted: false,
  pickup: null,
  dest: null,
  slot: null,
  vehicle_id: null,
};
