import React from 'react';
import { IoMdCall } from 'react-icons/io';
import va from '@vercel/analytics';

export default function Callbtn() {
  return (
    <div style={containerStyle}>
      <a
           href="tel:+61280024072 "
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          va.track('call');
        }}
        style={linkStyle}
      >
        <IoMdCall style={iconStyle} />
      </a>
    </div>
  );
}

const containerStyle = {
  position: 'fixed',
  bottom: '1rem',
  right: '1rem',
  zIndex: 50,
};

const linkStyle = {
  display: 'inline-block',
  backgroundColor: '#004ea8',
  borderRadius: '50%',
  padding: '12px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  textDecoration: 'none',
  cursor: 'pointer',
  transition: 'transform 0.3s',
};

const iconStyle = {
  fontSize: '24px',
  color: 'white',
};

// Hover effect
linkStyle[':hover'] = {
  transform: 'scale(1.1)',
};
