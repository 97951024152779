import { useState, useRef, useEffect } from 'react';
import Stars from '../../assets/images/stars.png'
import Person1 from '../../assets/images/person1.png'
import './CircularCarousel.css'

import { Navigation, Pagination, Scrollbar, A11y,Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import StarRating from './rating';

function CircularCarousel() {
  

  return (
    <>
    <Swiper
      // spaceBetween={50}
      slidesPerView={'auto'}
      autoplay={{
        delay: 8000,
        disableOnInteraction: false,
      }}
      modules={[Navigation, Pagination, Scrollbar, A11y,Autoplay]}

      navigation={true}
      loop={true} // Add the loop prop
    >
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img width={200} height={200} className='profileimg' src={require("../../assets/images/testimonial2.jpeg")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Vishaal</h5>
      <p className="card__text">“Booking a GoBlu-EV cab for my family's ride to Melbourne airport was a seamless experience. The entire journey was smooth, and we were impressed with the service provided. We couldn't stop ourselves from capturing the best ride experience in EV-cab. Highly recommended!.”</p>
    </div>
      </SwiperSlide>
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/testimonial1.jpeg")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Matt Clarke</h5>
      <p className="card__text">“Found GoBlu-EV online while searching for airport transfer.  Brilliant idea and excellent service. The driver was punctual and the car was super nice. The car was quiet and smooth. In about fifty dollars less than regular taxi to the airport they have Flat rate and easy payment. Delighted with the experience, will use again and highly recommend to friends and family. I'd give them ten stars if I could.
”</p>
    </div>
      </SwiperSlide>
{/*  */}
<SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/testimonial3.PNG")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Chetna Raj</h5>
      <p className="card__text">“I recently had my airport ride with GoBlu-EV, which was a great experience. The car reached on time for the scheduled pick up. The best part about my ride was the satisfaction of traveling in an EV Cab and contributing to the environment.
”</p>
    </div>
      </SwiperSlide>
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Kritika Tokas</h5>
      <p className="card__text">“I recently had the pleasure of using GoBlu-EV ride-share service, and it was a fantastic experience. The ride was quiet and smooth, without any emissions or noise pollution. I appreciated knowing that my trip has a significantly lower environmental impact, contributing to greener and cleaner future.
I highly recommend GoBlu-EV (electric ride-share) service to anyone looking for a reliable and Eco-friendly transportation to and from Melbourne airport. I will definitely use this service in future.
”</p>
    </div>
      </SwiperSlide>

      {/*  */}
      
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Jackie Woods</h5>
      <p className="card__text">“Excellent service. Easy booking system. Great updates on pick up arrival time for travel to airport. Spotlessly clean car. Extremely pleasant driver. Would highly recommend and will certainly use again. Great price too!”</p>
    </div>
      </SwiperSlide>
      {/*  */}
      
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Nick Barda</h5>
      <p className="card__text">“We had a terrific experience with GoBlueEv for our trip from Bentliegh to the Airport at 5:15am. They arrived ahead of schedule, and it was only $60 ($20 cheaper than Uber or similar), zero emissions, and I got a free cap for being one of their first customers!”</p>
    </div>
      </SwiperSlide>
      
      


      {/*  */}
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Judith Hopkinson</h5>
      <p className="card__text">“Absolutely would I recommend this service. I arranged for them to pick my elderly brother up from the airport and he was extremely happy with the service and the driver Manu. Good for you Goblu-ev we will be using this service again.🌏⭐⭐⭐⭐⭐”</p>
    </div>
      </SwiperSlide>
      {/*  */}
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Lachlan Burgess-Orton</h5>
      <p className="card__text">“Great experience and would reccomend to anyone. Friendly driver was on time, calling 5 minutes early to confirm which pickup zone we were in. Was also a better price than any other rideshare/taxi options available. All fairs were upfront before you booked and honoured”</p>
    </div>
      </SwiperSlide>

      {/*  */}
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Roxane McArdle</h5>
      <p className="card__text">“Googled transport from our accommodation to Melbourne Airport for our early flight a d came across this company, with their fixed rate. Efficient, picked up at the door and dropped off so easily. Friendly driver and a smooth ride from start to finish. Beautiful car on top of that! Highly recommend!”</p>
    </div>
      </SwiperSlide>

      {/*  */}
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Shankar Roy</h5>
      <p className="card__text">“I have nothing but praise for this service. They are courteous prompt reliable and cheaper than the other service.
The cars are spacious clean and comfortable. Contact is immediate and there are no hidden fees.
This service is the future of the industry and growth will reduce costs even further.
I have started spreading the info on this operation to friends and colleagues.
Give your support to these young entrepreneurs doing a great service to mankind.
Go blu you have my backing.
Thanks for the rides to and from the airport
Capt. Shankar Roy (Retd)
Singapore Airlines.
”</p>
    </div>
    
      </SwiperSlide>
      {/*  */}
      
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Bianca Pellizzari</h5>
      <p className="card__text">“Fast, reliable, no emissions and good communication when we needed to adjust pickup time. Found my new way of travelling to the airport from now on!!”</p>
    </div>
      </SwiperSlide>
      {/*  */}
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Kathy McLean</h5>
      <p className="card__text">“Great ride to the airport in an electric car. Very spacious and comfortable. Driver arrived early, helped with the bags and was lovely to talk to. Will definitely use again.”</p>
    </div>
      </SwiperSlide>
      {/*  */}
      <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Isha Guptaa</h5>
      <p className="card__text">“Hi. I ordered a ride to the airport and the driver was not only on time but very polite as well. The car was amazing and I helped the planet a little bit. Win win 😊”</p>
    </div>
      </SwiperSlide>
           {/*  */}
      
           <SwiperSlide className='testi-card card'>
      <div className="card__image">
      <img className='profileimg' src={require("../../assets/images/dummy_person.png")} alt="card image"/>
      {/* <img className='stars' src={Stars} alt="card image"/> */}
      <StarRating rating={5}/>
    </div>

    <div className="card__content">
      <h5 className="card__name">Randy Suryadinata</h5>
      <p className="card__text">“I saw Goblu-ev taxi service on Facebook and decided to give it a go for getting me home from the airport. The service is nothing short of excellent! Manu, my driver for the day, was friendly and courteous. He arrived at the airport-arrival pick-up area on time. The communication with the whole team was also been superb. I definitely recommend using Goblu-ev service and will be using their taxi service for many times more myself. Keep up the great service team!”</p>
    </div>
      </SwiperSlide>
    </Swiper>
   
    </>
  );
};

export default CircularCarousel;