import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from "@stripe/react-stripe-js";
import { useStateValue } from "../../../../context/StateProvider";
import { fetchSessionUrl, updateUser } from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from 'react-icons/ai';
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const stripePromise = loadStripe("pk_live_51O4dbmEWXZ4M1jtMyq63OPmSCtL9F4xs0e5OJfTn5apIWuAqYOAlDjGLDrS3d2BRfTpt2GjC3VwKlxC4kozrLGFW00ipNzrfel");

const AddCardModal = ({ closeModal, book }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [{ token }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchSecret = async () => {
    try {
      const response = await fetchSessionUrl(token);

      if (response && response.data && response.data.success) {
        setClientSecret(response.data.clientSecret);
      } else {
        navigate("/book/rides");
      }
    } catch (error) {
      console.error("Error fetching session URL:", error);
    }  finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSecret();
  }, []);

  const options = {
    clientSecret,
    onComplete: async () => {
      try{

        const response = await updateUser({ hasPaymentDetails: true }, token);
        if (response.status === 200) {
          await closeModal();
          await book();
        }
        else{
        toast.error("Error in adding card")

        }
      }
      catch(error){
        toast.error("Error in adding card")
      }
    
    }
  };

  return (
    <div style={modalStyle}>
    <div style={containerStyle}>
      <center>
        <h4>Add Card Details</h4>
      </center>
      {/* Close button */}
      <button style={closeButtonStyle} onClick={closeModal}>
        <AiOutlineClose size={24} />
      </button>
      {/* Modal content */}
      <div id="checkout" style={contentStyle}>
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          clientSecret && (
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )
        )}
      </div>
    </div>
  </div>
);
};

// Modal styles (updated containerStyle for max height and scrollable content)
const modalStyle = {
position: 'fixed',
top: 0,
left: 0,
right: 0,
bottom: 0,
display: 'flex',
alignItems: 'center',
justifyContent: 'center',
backgroundColor: 'rgba(0, 0, 0, 0.75)',
zIndex: 1000
};

const containerStyle = {
background: 'white',
width: '360px',
height: '500px', // Always set height to 500px
overflowY: 'auto', // Enable scrolling if content exceeds maxHeight
padding: '2rem',
borderRadius: '0.5rem',
boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
position: 'relative',
};

const closeButtonStyle = {
position: 'absolute',
top: '1rem',
right: '1rem',
color: '#666',
cursor: 'pointer',
};

const contentStyle = {
maxHeight: '100%', // Set the content maxHeight to 100%
overflowY: 'auto', // Enable scrolling if content exceeds maxHeight
};

export default AddCardModal;