import React, { useState,useEffect } from 'react';
import './Status.css'; // Create a CSS file for styling
import UpcomingRides from '../Upcoming card/UpcomingRides.jsx';
import PastRides from '../Past card/PastRides.jsx';
import ProfilePage from '../profile/profile.jsx';
import { pastBookingsApi } from "../../../services/api";
import { toast } from "react-toastify";
import { useStateValue } from "../../../context/StateProvider";
import Loader from '../../loader/loader';
import va from "@vercel/analytics";


 
function Status() {
    const [page, setPage] = useState('upcoming');
    const [pastBookings, setPastBookings] = useState([]);
    const [futureBookings, setFutureBookings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [{ token }] = useStateValue();

    const fetchBookings = async () => {
        try {
          const response = await pastBookingsApi(token);
    
          if (response.status === 200) {
            const { results } = response.data;

            // Filter future bookings with statuses "booked," "ongoing," and "upcoming"
            const futureBookings = results.filter((booking) => {
              const bookingStatus = booking.status;
              const isDesiredStatus = ["booked", "ongoing", "upcoming"].includes(bookingStatus);
            
              return isDesiredStatus;
            });
            
            // Filter past bookings with the status "completed"
            const pastBookings = results.filter((booking) => {
              const bookingStatus = booking.status; 
              const isCompleted = bookingStatus === "completed";            
              return isCompleted;
            });
            
            setFutureBookings(futureBookings);
            setPastBookings(pastBookings);
          } else {
            toast.error("Faild to fetch past bookings !");
          }
        } catch {
          toast.error("Faild to fetch past bookings !");
        } finally {
          setIsLoading(false);
        }
      };
    
      useEffect(() => {
        va.track("view_bookings");
    
        fetchBookings();
      }, []);
    

      const renderContent = () => {
        if (page === 'upcoming') {
          return (
            <>
              <div className="card-container  d-flex flex-wrap justify-content-center gap-5 pt-4">
                {/* Render the UpcomingRides component */}
                {futureBookings.map((booking) => (
                  <UpcomingRides booking={booking} key={booking.id} />
                ))}
              </div>
            </>
          );
        } else if (page === 'past') {
          return (
            <>
              <div className="card-container overflow-hidden d-flex flex-wrap justify-content-center gap-5 pt-4">
                {/* Render the PastRides component */}
                {pastBookings.map((booking) => (
                  <PastRides booking={booking} key={booking.id} />
                ))}
              </div>
            </>
          );
        } else if (page === 'profile') {
          return <ProfilePage />; // Render the Profile component
        }
      };
    
      return (
        <div className="app">
          <div className="sidebar  d-flex flex-column justify-content-between">
            <div className="page-navigation">
              <button
                onClick={() => setPage('upcoming')}
                className={page === 'upcoming' ? 'selected status_btn' : ''}
              >
                Upcoming
              </button>
              <button
                onClick={() => setPage('past')}
                className={page === 'past' ? 'selected status_btn' : ''}
              >
                Past
              </button>
              <button
                onClick={() => setPage('profile')}
                className={page === 'profile' ? 'selected status_btn' : ''}
              >
                Profile
              </button>
            </div>
          </div>
          {isLoading ? <Loader /> : <div className="content w-100">{renderContent()}</div>}
        </div>
      );
}

export default Status;
