export const actionType = {
  SET_USER: "SET_USER",
  SET_TOKEN: "SET_TOKEN",
  SET_LOADING: "SET_LOADING",
  SET_SLOT: "SET_SLOT",
  SET_VEHICLE_ID: "SET_VEHICLE_ID",
  SET_PICKUP: "SET_PICKUP",
  SET_DEST: "SET_DEST",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionType.SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case actionType.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case actionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case actionType.SET_PICKUP:
      return {
        ...state,
        pickup: action.pickup,
      };

    case actionType.SET_DEST:
      return {
        ...state,
        dest: action.dest,
      };
    case actionType.SET_SLOT:
      return {
        ...state,
        slot: action.slot,
      };

    default:
      return state;
  }
};

export default reducer;
