import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const commonrequest = async (methods, url, body, header) => {
  let config = {
    method: methods,
    url,
    headers: header
      ? header
      : {
          "Content-Type": "application/json",
        },
    data: body,
  };

  //axios instance
  return axios(config)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUser = async (data, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("GET", `/users/web/route`, data, header);
};

export const sendOtp = async (data, header) => {
  return await commonrequest(
    "POST",
    `/auth/user/request-otp-phone`,
    data,
    header
  );
};

export const verifyOTP = async (data) => {
  let header = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "content-type",
    "Access-Control-Allow-Methods": "GET",
  };
  return await commonrequest("POST", `/auth/user/phone-verify`, data, header);
};

export const updateUser = async (data, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("PATCH", `/users/web/route`, data, header);
};

export const updateRequest = async (param, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest(
    "GET",
    `/rides/updateRequest/${param}`,
    "",
    header
  );
};

export const serviceStatus = async (data) => {
  return await commonrequest("POST", `/zones/web/service-availability`, data);
};

export const estimatePrice = async (data) => {
  return await commonrequest("POST", `/rides/web/estimate-fare`, data);
};

export const slotdates = async (data, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("GET", `/rides/slotdates`, data, header);
};

export const slotAvailability = async (data, param, requestId, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest(
    "GET",
    `/rides/web/viewAvailableSlots?date=${param}&serviceid=${requestId}`,
    data,
    header
  );
};

export const discountedPrice = async (data, requestId) => {
  return await commonrequest(
    "GET",
    `/rides/${requestId}/discountedPrice`,
    data
  );
};

export const confirmBooking = async (data, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/rides/web/schedule`, data, header);
};

export const cancelBooking = async (data, token, id) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/rides/${id}/cancel`, data, header);
};

export const pastBookingsApi = async (token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("GET", `/rides/bookings`, "", header);
};

export const addPickupNote = async (body, token, id) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/rides/${id}/pickup-note`, body, header);
};

export const bookingInfo = async (data, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("GET", `/rides/bookings?_id=${data}`, "", header);
};

export const contactUs = async (data) => {
  // let header = {
  //   Authorization: `Bearer ${token}`,
  // };
  return await commonrequest("POST", `/users/contact`, data);
};

// deprecated
export const getTestimonials = async (data) => {
  return await commonrequest("get", `/ride/testimonials`, data);
};
export const getCO2 = async (data, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/ride/carbon-status`, data, header);
};

export const serviceDetails = async (data) => {
  return await commonrequest("GET", `/ride/service/${data}`);
};

export const fetchSessionUrl = async (token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest(
    "GET",
    `/payment/create-checkout-session`,
    "",
    header
  );
};

export const fetchPayment = async (token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("GET", `/payment/details`, "", header);
};

export const getPublishableKey = async (token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("GET", `/payment/get-publishable-key`, "", header);
};

export const removePayment = async (token, id) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest(
    "GET",
    `/payment/remove-card?id=${id}`,
    "",
    header
  );
};

export const getPartnerProfile = async (token, body) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/partner/profile`, body, header);
};
