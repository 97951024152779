import React from 'react';
import './Footer.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="text-light footer-with-bg">
      <div className="containerx d-flex justify-content-center ">
        <div className="contentcontetxad d-flex  w-100 flex-wrap justify-content-between">
          <div className="logox col-md-2">

            <ul className="list-unstyled">
              <li><a href="/">
                <img src={logo} alt="" />
              </a></li>
              <li className='mt-3'>
              © 2023 Renaus Power Pty. Ltd. All rights reserved
              </li>
              <li className='mt-3'>
             <Link    target="_blank"
                  to="https://www.facebook.com/GoBluAustralia?mibextid=LQQJ4d"><FaFacebook style={{height:24,width:24,marginRight:5}}/></Link>
             <Link    target="_blank"
                  to="https://www.linkedin.com/company/goblu-ev/"><FaLinkedin style={{height:24,width:24,marginRight:5}}/></Link>
             <Link       target="_blank"
                  to="https://www.instagram.com/goblu_australia/"><FaInstagram style={{height:24,width:24,marginRight:5}}/></Link>


              </li>
            </ul>

          </div>
          <div className="maincontentx d-flex">


            <div className="footercontentxone d-flex ">


              <div className="">
                <h5 className='Footer-Head'>Company</h5>
                <ul className="list-unstyled w-70">
                  <li><Link to="/about">About us</Link></li>
                  <li><Link to="/book">Book a ride</Link></li>
                  <li><Link   target="_blank"
                    to="https://apps.apple.com/in/app/goblu-ev/id6464328871">Download app</Link></li>
                  <li><Link to="/contact">Contact us</Link></li>
                  <li>     <Link
                target="_blank"
                    to="https://goblu-ev.com/coupon.html"
                    
                  >
                Coupon terms & condition
                  </Link></li>
                </ul>
              </div>
              <div className="">
                <h5 className='Footer-Head'>Driver</h5>
                <ul className="list-unstyled">
                  {/* <li><a href="#">Become a <br /> driver</a></li> */}
                  <li>     <Link
                target="_blank"
                    to="https://drive.google.com/file/d/1w2Ob997MOeizqQuZGGVVgL8qqxBT3S_W/view?usp=drive_link"
                    className="text-body-color hover:text-primary mb-2 inline-block text-base leading-loose"
                  >
                General terms & condition
                  </Link></li>
                </ul>
              </div>
        
            </div>
            <div className="footercontenttwox d-flex">


              <div className="">
                <h5 className='Footer-Head'>Legal</h5>
                <ul className="list-unstyled">
                  <li>      <Link
                  target="_blank"
                    to="https://drive.google.com/file/d/1eLsXypAvLMHEQETF-EUeIyYxTTXSRRZD/view?usp=drive_link"
                    className="text-body-color hover:text-primary mb-2 inline-block text-base leading-loose"
                  >
                    Privacy policy
                  </Link></li>
                </ul>
              </div>
              <div className="">
                <h5 className='Footer-Head'>Contact Us</h5>
                <ul className="list-unstyled">
                  {/* <li><a href='tel:+61399173656 '><i class="bi bi-telephone-fill"></i> +61 3 9917 3656 </a></li> */}
                  <li><a href='tel:+61280024072 '><i class="bi bi-telephone-fill"></i> +61 2 8002 4072 </a></li>
                  <li><a href='mailto:support@goblu-ev.com'><i class="bi bi-envelope-fill"></i> support@goblu-ev.com</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;