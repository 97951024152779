import React from 'react';
import './card1.css'
const Card1 = ({ description, alignment,image }) => {
  const cardClassName = `card card-about ${alignment}`;
  return (
    <div className={cardClassName}>
        <div className="cardimage">
            {image && <img src={image} alt={`Image for`} height="240px" width="100%" />}
        </div>
        <div className="cardtext">
            <p>{description}</p>
        </div>
    </div>
  );
};

export default Card1;
