import React, { useState, useRef, useEffect } from 'react';
import './LocationCard.css'
import swap from '../../assets/images/swap.png';
import { Link, useNavigate } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import { toast } from "react-toastify";
import { serviceStatus } from "../../services/api";
import { useStateValue } from "../../context/StateProvider";
import va from "@vercel/analytics";

import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

function LocationCard() {
    const [pickupInputValue, setPickupInputValue] = useState("");
    const [destInputValue, setDestInputValue] = useState("");
    const [{pickup,dest,service_id}, dispatch] = useStateValue();
    const navigate = useNavigate();

  const autocompleteRefPickup = useRef(null);
  const autocompleteRefDest = useRef(null);
  const pickupref = useRef("");
  const destref = useRef("");


  const [pickupPlace, setPickupPlace] = useState(null);
  const [destinationPlace, setDestinationPlace] = useState(null);

  const autocompleteOptions = {
      componentRestrictions: { country: "au" },
    };


  const handlePlaceSelectPickup = () => {
      if (autocompleteRefPickup.current !== null) {
        const place = autocompleteRefPickup.current.getPlace();
  
      //   setPickupLocation({
      //     lat: place.geometry.location.lat(),
      //     long: place.geometry.location.lng(),
      //   });
        setPickupInputValue(pickupref.current.value);
        // handleAutoSubmit()
  
  
  
      } else {
        console.log("Autocomplete for pickup is not loaded yet!");
      }
    };
  
    const handlePlaceSelectDest = () => {
      if (autocompleteRefDest.current !== null) {
        const place = autocompleteRefDest.current.getPlace();
  
      //   setDestLocation({
      //     lat: place.geometry.location.lat(),
      //     long: place.geometry.location.lng(),
      //   });
        
        setDestInputValue(destref.current.value);
        // handleAutoSubmit()
      } else {
        console.log("Autocomplete for destination is not loaded yet!");
      }
    };

  //   const handleLocationSwap = () => {
   
  // // Swap input values
  // const tempPickupInputValue = pickupInputValue;
  // alert(destInputValue)
  // setPickupInputValue(destInputValue);
  // setDestInputValue(tempPickupInputValue);

  // // Swap input refs
  // const tempPickupRef = pickupref.current;
  // pickupref.current = destref.current;
  // destref.current = tempPickupRef;

  // // Swap autocomplete refs
  // const tempAutocompletePickup = autocompleteRefPickup.current;
  // autocompleteRefPickup.current = autocompleteRefDest.current;
  // autocompleteRefDest.current = tempAutocompletePickup;
  //   };


  const serviceable_text = () => (
    <div style={{fontSize:"14px",color:"black"}}>
     Not in our serviceable regions !<br/> <Link  to="/service"><span style={{fontSize:"14px",color:"black"}}><span className='text-primary'>Click here </span>to view serviceable regions</span></Link>
    </div>
  );

  const handleService = async () => {
    va.track("location_selected_landing");
    ReactGA.event({
      category: "booking",
      action: "choose_location",
    });
    ReactPixel.trackSingleCustom('754277743000300', "choose_location_goblu",{booking:"choose_location_goblu"});
    const pickupPlace = autocompleteRefPickup.current.getPlace();
    const destPlace = autocompleteRefDest.current.getPlace();
    if (
      !pickupPlace ||
      !pickupref.current.value ||
      !destPlace ||
      !destref.current.value
    ) {
      toast.error("Select locations!");
      return;
    }

    let pickupLocation = {
      name: pickupref.current.value,
      ...{
        lat: pickupPlace.geometry.location.lat(),
        long: pickupPlace.geometry.location.lng(),
      }
    };
    let dropLocation = {
      name: destref.current.value,
      ...{
        lat: destPlace.geometry.location.lat(),
        long: destPlace.geometry.location.lng(),
      }
    };
    dispatch({ type: "SET_LOADING", isLoading: true });

    const response = await serviceStatus({ pickupLocation, dropLocation, utmMedium: localStorage.getItem('utmMedium'),
    utmSource: localStorage.getItem('utmSource'),
    utmCampaign: localStorage.getItem('utmCampaign'),gclid:localStorage.getItem('gclid') });

    if (response.status === 200 && response.data.status) {
  
      pickupLocation = {
        type:pickupPlace.types,
        ...pickupLocation
      }

    dropLocation = {
      type:destPlace.types,
      ...dropLocation
    }

      dispatch({ type: "SET_LOADING", isLoading: false });
      dispatch({ type: "SET_PICKUP", pickup: pickupLocation });
      dispatch({ type: "SET_DEST", dest:dropLocation });
      navigate("/book/slot");
    } else {
      va.track("location_notsvc");
      dispatch({ type: "SET_LOADING", isLoading: false });

      toast.info(
        serviceable_text,{
          autoClose:false
        }
      );
    }
  };

    return (
        <div className="newcardprop mt-5">
            <div className="starx">
                <p className='fivestarx'>
                    5<i className='bi bi-star-fill'></i>on Google
                </p>
            </div>
            <p className="goblu-ev mb-2 pt-2">
            Cruise in style <br />
            with <span className='goblu'>GoBlu-</span> <span className='ev'>EV</span>
            </p>
            <div className="location w-100  d-flex justify-content-start  align-items-center gap-4 pt-4">
                <div className="icons d-flex ms-3">
                    {/* <div className="borderone"> */}
                        <div className="greendot mb-2"></div>
                    {/* </div> */}
                    <div className="line"></div>
                    {/* <div className="bordertwo"> */}
                        <div className="reddot mt-2"></div>
                    {/* </div> */}
                </div>
                <div className="places">

                <Autocomplete
                options={autocompleteOptions}
                onLoad={(autocomplete) => {
                  autocompleteRefPickup.current = autocomplete;
                  console.log("Autocomplete for pickup loaded:", autocomplete);
                }}
                onPlaceChanged={handlePlaceSelectPickup}
              >

<input
                            ref={pickupref}
                            type="text"
                            placeholder="Pickup Location"
                           
                            onChange={(e) => setPickupInputValue(e.target.value)}
                        />
              </Autocomplete>
                    <div className="horizontalline mt-3 mb-3">
                    {/* <div className="swap-icon" onClick={handleLocationSwap}>
                        <img src={swap} alt="" height="43px" />
                    </div> */}
                    </div>
                    <Autocomplete
                options={autocompleteOptions}
                onLoad={(autocomplete) => {
                  autocompleteRefDest.current = autocomplete;
                  console.log(
                    "Autocomplete for destination loaded:",
                    autocomplete
                  );
                }}
                onPlaceChanged={handlePlaceSelectDest}
              >
                          <input
                     
                            ref={destref}
                            type="text"
                            placeholder="Enter Destination"
                            
                          
                        />
              </Autocomplete>
              
                </div>
                
            </div>
            <button onClick={
               handleService
            } className="check-fare mt-4">
                    CHECK FARE 
            </button>
            <p className='offer mt-1'> Anywhere to Anywhere in melbourne</p>
            <div className="discountx mt-3">
                <p>20-25% Cheaper</p>
            
            </div>
        </div>
    );
}

export default LocationCard;


