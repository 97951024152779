import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="bg-white shadow-lg rounded-lg p-4">
        <h1 className="text-4xl text-dark font-weight-bold mb-4">404</h1>
        <p className="text-secondary mb-4">
          Oops! The page you're looking for could not be found.
        </p>
        <button
          onClick={() => {
            navigate("/", { replace: true });
          }}
          className="btn btn-primary"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
