import React, { useEffect, useRef, useState } from "react";

import "./Details.css";
import { FaEdit,  } from "react-icons/fa";
import { BsCalendar, BsClock } from "react-icons/bs";
import leaf from '../../../../assets/images/leaf.png';
import { Spinner } from "react-bootstrap";
import car from '../../../../assets/images/car.png';
import person from '../../../../assets/images/people.png';
import large from '../../../../assets/images/large-bag.png';
import small from '../../../../assets/images/small-bag.png';
import { Modal, Button } from "react-bootstrap";
// slot imports
import l from "polyline-encoded";
import { GoogleMap, MarkerF, PolylineF } from "@react-google-maps/api";
import { useStateValue } from "../../../../context/StateProvider";
import {
  confirmBooking,
  discountedPrice,
  estimatePrice,
  getUser,
  serviceDetails,
  slotAvailability,
  slotdates,
} from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import Loader from "../../../loader/loader";
import { toast } from "react-toastify";
import {
  co2_calculation,
  convertTo24HourFormat,
  convertToAmPm,
  truncateText,
} from "../../../../services/helper";
import FareModal from "../fare/fareModal";
import AuthModal from "../auth/authModal";
import ProfileModal from "../auth/profileModal";
import Callbtn from "../../../layout/call";
import va from "@vercel/analytics";
import APPDownloadNew from "../../../layout/app_new";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import AddCardModal from "../auth/card";

export default function Slot() {
  const [{ token, pickup, dest, }, dispatch] =
  useStateValue();

const mapContainerStyle = {
  width: "100%",
  height: "451px",
};

const center = { lat: -37.6708, lng: 144.8429 }; // Center the map around Melbourne

const [slots, setSlots] = useState([]);
const [dates, setDates] = useState([]);
const [times, setTimes] = useState([]);
const [fare, setFare] = useState(null);
const [priceTable, setPriceTable] = useState();
const [isModalOpen, setIsModalOpen] = useState(false);
const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
const [addCardModalOpen, setAddCardModalOpen] = useState(false);
const [co, setCo] = useState(null);
const [vehicleId, setVehicle] = useState("");
const [requestId,setRequestId]=useState("");
const [selectedDate, setSelectedDate] = useState(null);
const [selectedSlot, setSelectedSlot] = useState(null);
const [showStripeModal, setShowStripeModal] = useState(false);
const [isLoading, setIsLoading] = useState(true);
const [pickupLocation, setPickupLocation] = useState("");
const [destLocation, setDestLocation] = useState("");
const [decodedCoordinates, setDecodedCoordinates] = useState("");
const [slotLoading,setSlotLoading] = useState(true);
const [showAppModal, setShowAppModal] = useState(false);
const [disPrice,setDisPrice] = useState()
const [selectedPaymentMode, setSelectedPaymentMode] = useState("online");

const navigate = useNavigate();

const handlePaymentModeChange = (mode) => {
  setSelectedPaymentMode(mode);
};

const handleOpenAppModal = () => {
  ReactGA.event({
    category: "booking",
    action: "open_app_modal",
  });
 
  setShowAppModal(true);
};

const handleCloseAppModal = () => {
  setShowAppModal(false);
};

const mapRef = useRef(null);

const polylineOptions = {
  strokeColor: "#0000ff", // Black color
  strokeOpacity: 1,
  strokeWeight: 2, // Increase the stroke weight for a thicker line
};

const getDates = async () => {


  try {
    // const response = await slotAvailability("", token);

    // if (response.status === 200) {
    //   setSlots(response.data);
    //   const dates = Object.keys(response.data);
    //   setDates(dates);
    // }
    const response = await slotdates("", token);

    if (response.status === 200) {
     
      const dates = response.data.slots;
      setDates(dates);
    }
  } catch {
    // Handle error
  } finally {
    setSlotLoading(false); // Set isLoading to false after getting slots
  }
};


const getSlots = async (dateParam) => {


  try {
    setSlotLoading(true); 
    setTimes([])
    const response = await slotAvailability("", dateParam,requestId,token);

    if (response.status === 200) {
      setSlots(response.data);
      const selectedDateSlots = response.data[dateParam] || [];

  setTimes(selectedDateSlots);
  setSelectedSlot(null)
    }

  } catch {
    // Handle error
  } finally {
    setSlotLoading(false); // Set isLoading to false after getting slots
  }
};

const onMapLoad = (map) => {
  mapRef.current = map;

  // Define padding in degrees to create a gap around the markers
  const padding = 0.001;

  // Extend the bounds with additional latitude and longitude values
  const bounds = new window.google.maps.LatLngBounds();
  bounds.extend({
    lat: pickupLocation.lat + padding,
    lng: pickupLocation.lng + padding,
  });
  bounds.extend({
    lat: destLocation.lat - padding,
    lng: destLocation.lng - padding,
  });

  map.fitBounds(bounds, { animation: window.google.maps.Animation.BOUNCE });
};

const discountedFare = async (id)=>{
try{
const response = await discountedPrice("",id);

if(response.status===200){
  setDisPrice(response.data)
}

}
catch(error){
  console.error("Error fetching discounted details:", error);
}
}

const fetchServiceDetails = async () => {
  try {

    ReactGA.event({
      category: "booking",
      action: "view_fare",
    });
    ReactPixel.trackSingleCustom('754277743000300', "view_fare_goblu",{booking:"view_fare_goblu"});
    const response = await estimatePrice({
      "startLocation":{
          "name":pickup.name,
          "coordinates": [pickup.long,pickup.lat]
      },
      "endLocation":{
          "name":dest.name,
          "coordinates":[dest.long,dest.lat]
      }
    
  
  });
    if (response.status === 200) {
      const {id,time,  distance ,co2Prediction,polyline} = response.data;
      setRequestId(id);
      setCo(co2Prediction);
      setFare(response.data.packages[0].totalFare);
      setPriceTable({...response.data.packages[0],distance,time});
      localStorage.setItem("prevRequest",id)
      discountedFare(id);
      // setPolyline(polyline)
      let dcc = l.decode(polyline);
      dcc = dcc.map((coordinate) => ({
        lat: coordinate[0],
        lng: coordinate[1],
      }));

      
    
      setDecodedCoordinates(dcc);
    }
  } catch (error) {
    console.error("Error fetching service details:", error);
  } finally {
    setIsLoading(false); // Set isLoading to false after getting slots
    getDates();
    setShowAppModal(true)
  }
};

useEffect(() => {
  if ( !pickup || !dest ) {
    navigate("/book");
    return;
  }
  setPickupLocation({
    lat: pickup.lat,
    lng: pickup.long,
  });

  setDestLocation({
    lat: dest?.lat,
    lng: dest?.long,
  });

  // if (service_id) {
    fetchServiceDetails();
  // }

 
}, [, pickup, dest,]);

const handleTimeChange = (e) => {
  setVehicle(e.target.value);
  var index = e.nativeEvent.target.selectedIndex;
  var text = e.nativeEvent.target[index].text;
  setSelectedSlot(text);
};
const onLoad = (marker) => {
  console.log("marker: ", marker);
};

// modal open fare modal
const openModal = () => {

  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};

// modal open auth modal
const openAuthModal = () => {
  setIsAuthModalOpen(true);
};

const closeAuthModal = () => {
  setIsAuthModalOpen(false);
};

// modal open profile

const openProfileModal = () => {
  setIsProfileModalOpen(true);
};

const closeProfileModal = () => {
  setIsProfileModalOpen(false);
};

//modal card open
const openAddCardModal = () => {
  setAddCardModalOpen(true);
};

const closeAddCardModal = () => {
  setAddCardModalOpen(false);
};
// handle confirm booking button

const handleAction = async () => {
  ReactGA.event({
    category: "booking",
    action: "choose_slot",
  });
  ReactPixel.trackSingleCustom('754277743000300', "choose_slot_goblu",{booking:"choose_slot_goblu"});
  if (
    (pickup.type.includes("locality") || pickup.type.includes("political") || pickup.type.includes("route") )&& !pickup.name.toLowerCase().includes("airport")
  ) {
    toast.error("Please choose an exact pickup location");
    return;
  }
  
  if (
    (dest.type.includes("locality") || dest.type.includes("political") || dest.type.includes("route")) && !dest.name.toLowerCase().includes("airport")
  ) {
    toast.error("Please choose an exact drop location");
    return;
  }
  if (!selectedDate || !selectedSlot) {
    toast.error("Select Slot");
    return;
  }


  try {
    const response = await getUser("", localStorage.getItem("token"));
    if (response.status === 200) {
      if ((response.data.isProfileCompleted && response.data.hasPaymentDetails )|| (response.data.isProfileCompleted && selectedPaymentMode==="offline") ) {
        await book();
      } else if(response.data.isProfileCompleted && !response.data.hasPaymentDetails && selectedPaymentMode==="online") {
    
        setAddCardModalOpen(true);
      }else{
        setIsProfileModalOpen(true);

      }
    } else {
      setIsAuthModalOpen(true);
    }
  } catch {
    setIsAuthModalOpen(true);
  }
};

const book = async () => {

  va.track("try_booking");
  try {
    if (!selectedDate || !selectedSlot) {
      toast.error("Select Slot");
      return;
    }

    const timeslot = convertTo24HourFormat(selectedSlot);
    const slot = `${selectedDate} ${timeslot}`;

    if ( !slot || !vehicleId) {
      navigate("/book");
      return;
    }

    setIsLoading(true);

    const response = await confirmBooking(
      { requestId, slot, vehicleId ,mode:selectedPaymentMode, refCode:localStorage.getItem('partnerId')},
      localStorage.getItem("token")
    );

    if (response.status === 200) {
      if (response.data.status) {
        localStorage.setItem("booking", response.data.booking.id);

        va.track("booking_success");
        navigate("/book/confirm", { replace: true });
        setIsLoading(false);
      } else {
        va.track("booking_redirect_slot", { message: response.data.message });
        toast.error(response.data.message);
        navigate("/book/slot", { replace: true });
        setIsLoading(false);
      }
    } else {
      va.track("booking_failed");
      // toast.error("Booking Failed!");
      setIsLoading(false);
    }
  } catch {
    va.track("booking_failed");
    toast.error("Booking Failed!");
    setIsLoading(false);
  } finally {
    setIsLoading(false);
  }
};

  return (
    <>
    <div>
      
     {isLoading ? (
              <Loader />
            ) : (
              <>
                {/* googlemap */}
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  // center={center}
                  zoom={10}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    styles:[
                      {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "administrative.land_parcel",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "administrative.neighborhood",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "landscape.natural",
                        "stylers": [
                          {
                            "saturation": -30
                          },
                          {
                            "lightness": 20
                          }
                        ]
                      },
                      {
                        "featureType": "poi",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "poi",
                        "elementType": "labels.text",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "transit",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "water",
                        "elementType": "labels.text",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      }
                    ]
                  
                  }}
                  onLoad={onMapLoad}
                >
                  {pickupLocation && (
                    <MarkerF
                      position={pickupLocation}
                      onLoad={onLoad}
                      icon={{
                        url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                      }}
                    />
                  )}
                  {destLocation && (
                    <MarkerF
                      position={destLocation}
                      icon={{
                        url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                      }}
                    />
                  )}
                  {pickupLocation && destLocation && (
                    <PolylineF
                      path={decodedCoordinates}
                      options={polylineOptions}
                    />
                  )}
                </GoogleMap></>)}

   {
    isLoading ? <Loader/> :   <div className="slot__container shadow-lg  p-3">
    {/* outer box */}

  <div className=" slot__outer__container">
      
      {/* location section */}
      <div className="location w-100  d-flex justify-content-start  align-items-center gap-4 pt-3">
    <div className="icons d-flex">
      <div className="borderone">
        <div className="circleone"></div>
      </div>
      <div className="line"></div>
      <div className="bordertwo">
        <div className="circletwo"></div>
      </div>
    </div>
    <div className="places">
 <div className="d-flex flex-row justify-content-between align-items-center">
 <p className=' my-2 '>{pickup.name}</p>
{/* <div> <FaEdit/></div> */}
 </div>
      <div className="horizontal-line"></div>
      <div className="d-flex flex-row justify-content-between align-items-center">
 <p className=' my-2 '>{dest.name}</p>
{/* <div> <FaEdit/></div> */}
 </div>
    </div>
  </div>
   
    {/* co2 saving section */}
    
{
co &&   <div className="co__info w-100 d-flex justify-content-center align-items-center p-3 gap-2 mt-1">
<img src={leaf} alt="leaf__icon" />
<p className=' m-0 fw-medium'>You will save {co}kg Co2 with this ride</p>
</div>
}
    {/* vehicle section */}
    <div className=' pt-0 pb-3 ps-3 pe-3'>
    
      <div className="car-details  d-flex  align-items-center justify-content-between">
        <div className="details d-flex gap-3 align-items-center">
          <div className="car">
            <img src={car}  alt="" />
            <p>BYD Atto 3</p>
          </div>
          <div className="vertical-line"></div>
          <div className="breakdown d-flex flex-column justify-content-start align-items-start">
            <p className=' fs-4'><img src={person} alt="" /> 4 Persons</p>
            <p className=' fs-4'><img src={large} alt="" /> 2 Large Bags</p>
            <p className=' fs-4'><img src={small} alt="" /> 2 Small Bags</p>
          </div>
        </div>
        <div   onClick={openModal} style={{cursor:"pointer"}} className="car-fare d-flex flex-column justify-content-start">
            <p className=' m-0 p-0 d-flex align-items-center justify-content-between'>Fixed Fare<img width={15} height={15} src={require('../../../../assets/images/information.svg').default} alt="leaf__icon" /> </p>
           {
            fare &&  <p className=' m-0' style={{color:"black"}}>A${fare.toFixed(2)}</p>
           }
          </div>
      </div>
    </div>
    {/*      note section */}
    <div>
    {/* <p className='text-center'>
  <b>
    Click{' '}
    <span
      // className="btn btn-link text-blue"
      style={{ textDecoration: 'underline',color:"blue", cursor: 'pointer' }}
      onClick={handleOpenAppModal}
    >
      here
    </span>{' '}
    to download our app for an instant 20% discount on your first ride!
  </b>
</p> */}

   

    </div>

    {/* date section */}
   <div>
  <div className="container mt-3">
<div className="row">
  <div className="col-6">
    <div className="input-group">
      <select  required={true}
                    onChange={async(e) => {
                      await getSlots(e.target.value)
                      
                      setSelectedDate(e.target.value);
                    }} className="form-select custom-select " style={{ outline: 'none',  boxShadow: "none",outlineColor:"transparent" }}>
        <option> Date</option>
     
        {dates.map((date, key) => (
                      <option key={key} value={date}>
                        {date}
                      </option>
                    ))}
      </select>
      <span className="input-group-text">
        <BsCalendar />
      </span>
    </div>
  </div>

  {slotLoading ? (
    <div className="d-flex justify-content-center mt-3">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Fetching slots...</span>
      </Spinner>
      <span className="ms-2">Fetching slots...</span>
    </div>
  ) :
  <div className="col-6">
    <div className="input-group">
      <select    onChange={handleTimeChange} className="form-select custom-select" style={{  outline: 'none',  boxShadow: "none",outlineColor:"transparent" }}>
        <option> Time</option>
        {times.map((time, key) => (
                      <option value={time.id} key={key}>
                        {convertToAmPm(time.time)}
                      </option>
                    ))}
      </select>
      <span className="input-group-text">
        <BsClock />
      </span>
    </div>
  </div>}
</div>
</div>
  </div>


      {/* Payment Mode Section */}
<div className="d-flex flex-column justify-content-center container mx-3 mt-2">
<div className="text-left mb-1">
            <b><h5 className="h6 ">Choose Payment Mode:</h5></b>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMode"
              id="offline"
              checked={selectedPaymentMode === "offline"}
              onChange={() => handlePaymentModeChange("offline")}
            />
            <label className="form-check-label small" htmlFor="offline">
              Pay later to the driver after the ride. All cards accepted
            </label>
          </div>
          <div className="form-check mt-2">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMode"
              id="online"
              checked={selectedPaymentMode === "online"}
              onChange={() => handlePaymentModeChange("online")}
            />
            <label className="form-check-label small " htmlFor="online">
            Save Card details. Travel hassle-free with auto debit  &nbsp;
{/* Powered by Stripe */}
<a
          href="#"
        
          onClick={() => setShowStripeModal(true)}
        >
          <img
            height={23}
            width={100}
            src="https://cdn.brandfolder.io/KGT2DTA4/at/g65qkq94m43qc3c9fqnhh3m/Powered_by_Stripe_-_black.png?width=400&height=91"
            alt="Powered by Stripe"
          />
        </a>
            </label> 
          </div>

  </div>
    {/* button */}
    <div>

 <center>
 <button   onClick={handleAction} className="book mt-4">
               Book Your EV
        </button>
 </center>
    </div>
{/* end */}
    </div>
 

  </div>
   }
     
      <center>
       
      {/*fare  modal */}
      {isModalOpen && (
        <FareModal closeModal={closeModal} priceTable={priceTable} />
      )}

      {/*auth  modal */}
      {isAuthModalOpen && (
        <AuthModal
          closeModal={closeAuthModal}
          dispatch={dispatch}
          token={token}
          book={book}
          addCardModalOpen = {openAddCardModal}
          selectedPaymentMode={selectedPaymentMode}
          profileModalOpen={openProfileModal}
        />
      )}

      {/*profile  modal */}
      {isProfileModalOpen && (
        <ProfileModal
          closeModal={closeProfileModal}
          dispatch={dispatch}
          token={token}
          book={book}
          isBooking={true}
          selectedPaymentMode={selectedPaymentMode}
          addCardModalOpen = {openAddCardModal}
        />
      )}

      {/* add card modal */}
      {addCardModalOpen && (
        <AddCardModal
          closeModal={closeAddCardModal}
          dispatch={dispatch}
          token={token}
          book={book}
          isBooking={true}
        />
      )}

      {/* modal for app download */}

<APPDownloadNew disPrice={disPrice} show={showAppModal} price={fare} handleClose={handleCloseAppModal}  />

 {/* Modal for Powered by Stripe */}
 <Modal show={showStripeModal} onHide={() => setShowStripeModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Saving Card Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
<ul>
<li>
      We use Stripe as our payment gateway, one of the most secure and trusted platforms in Australia.
    </li>
    <li>
    We do not store any of your payment details on our servers. Your financial information is handled securely by Stripe, ensuring a safe and reliable payment experience.
    </li>

</ul>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowStripeModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

     </center>
    </div>

    <Callbtn/>
     </>
  );
}
