import React from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function AddCardSuccess() {
  const handleBackButtonClick = () => {
    // Implement the logic to navigate back
    // You can use react-router, window.history, or any other navigation method here
  };

  return (
    <div className="container " style={{marginTop:"100px"}}>
      <div className="row justify-content-center">
        <div className="col-md-6 text-center">
          <BsCheckCircle color="green" size={100} className="mb-4" />
          <h2 className="text-success">Card Details Successfully Added!</h2>
          <p className="lead">You have successfully added your card details.</p>
          <button className="btn btn-primary" onClick={handleBackButtonClick}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
