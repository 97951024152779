import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";
import { sendOtp, updateRequest, verifyOTP } from "../../../../services/api";
import OtpInput from "react-otp-input";
import va from "@vercel/analytics";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import { Spinner } from "react-bootstrap";

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.75)",
  zIndex:1000
};

const containerStyle = {
  background: "white",
  padding: "2rem",
  borderRadius: "0.5rem",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  position: "relative",
};

const closeButtonStyle = {
  position: "absolute",
  top: "1rem",
  right: "1rem",
  color: "#666",
  cursor: "pointer",
};

const AuthModal = ({ closeModal, book, dispatch, token, profileModalOpen,addCardModalOpen ,selectedPaymentMode}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [sendOtpLoader,setSendOtpLoader] = useState(false)

  const handleVerifyOTP = () => {
    // Add your OTP verification logic here
    // setShowModal(false);
  };

  // send otp logic
  const handleSendOTP = async () => {
    // Validate the phone number
    ReactGA.event({
      category: "auth",
      action: "send_otp",
    });
    ReactPixel.trackSingleCustom('754277743000300', "send_otp_goblu",{booking:"send_otp_goblu"});

    setSendOtpLoader(true)

    if (!phoneNumber) {
      toast.error("Please enter a phone number");
      return;
    }

    // Send OTP and handle the response
    const response = await sendOtp({ phoneNumber: phoneNumber });
    if (response.status === 200) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("phone_number");
      localStorage.removeItem("booking");
      localStorage.setItem("phone_number", phoneNumber);

      setOtpSent(true);
    setSendOtpLoader(false)

      setOtp("");
    } else {
    setSendOtpLoader(false)

      // Display an error message
      toast.error("Failed to send OTP.Invalid number !");
    }
  };

  //   otp handle
  const handleVerify = async (otp) => {
    va.track("handle_otp");
    // Perform OTP validation
    ReactGA.event({
      category: "auth",
      action: "verify_otp",
    });
    ReactPixel.trackSingleCustom('754277743000300', "verify_otp_goblu",{booking:"verify_otp_goblu"});
    if (otp.length !== 4) {
      toast.error("Please enter a valid OTP.");
      return;
    }

    try {
      // Send OTP verification request
      const response = await verifyOTP(
        { otp: otp, phoneNumber: localStorage.getItem("phone_number") },
        token
      );

      if (response.status === 200) {
        const { user, tokens } = response.data;

        // Update user information in the state
        dispatch({ type: "SET_TOKEN", token: tokens.access.token });

        try{
          updateRequest(localStorage.getItem('prevRequest'),tokens.access.token )
        }
        catch(error){
          
        }

        localStorage.setItem("token", tokens.access.token);
        localStorage.removeItem("phone_number");
        localStorage.removeItem("prevRequest");


        if (!user.isProfileCompleted) {
          closeModal();
          profileModalOpen();
        } else if(!user.hasPaymentDetails && selectedPaymentMode==="online"){
                
          dispatch({ type: "SET_USER", user: user });
          localStorage.setItem("user", JSON.stringify(user));
          closeModal();
          addCardModalOpen()
        } else{
          // Redirect to the booking page
          dispatch({ type: "SET_USER", user: user });
          localStorage.setItem("user", JSON.stringify(user));
          closeModal();
          await book();
        }
      } else {
        toast.error("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred.  Please try again later.");
    }
  };

  const handleInputChange = (newOtp) => {
    setOtp(newOtp);

    if (newOtp.length === 4) {
      handleVerify(newOtp); // Call the verification logic when all numbers are entered
    }
  };

  return (
    <div style={modalStyle}>
      <div style={containerStyle}>
        <button style={closeButtonStyle} onClick={closeModal}>
          <AiOutlineClose size={20} />
        </button>
        {!otpSent ? (
          <>
            <h2 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "1rem" }}>
              Enter Phone Number
            </h2>
            <PhoneInput
              placeholder="Enter phone number"
              value={phoneNumber}
              international
              defaultCountry={"AU"}
              onChange={setPhoneNumber}
              style={{
                border: "2px solid #E5E7EB",
                borderRadius: "4px",
                width: "100%",
                padding: "1rem 1rem", // equivalent to py-5 px-4 in Tailwind CSS
                color: "#4B5563",
                lineHeight: "1.25", // equivalent to leading-tight in Tailwind CSS
                outline: "none",
                backgroundColor: "white",
                transition: "background-color 0.2s, border-color 0.2s",
                fontSize: "1.25rem", // equivalent to text-xl in Tailwind CSS
              }}
            />
            <button
              style={{
                marginTop: "1rem",
                width: "100%",
                background: 'linear-gradient(to right, #0095dd, #004ea8)',
                color: "white",
                fontWeight: "bold",
                padding: "0.5rem 1rem",
                borderRadius: "4px",
                cursor: "pointer",
                border: "none",
              }}
              onClick={handleSendOTP}
              disabled={!phoneNumber}
            >
  {
               sendOtpLoader ?   <Spinner animation="border" role="status">
               <span className="visually-hidden">Loading...</span>
             </Spinner> : <span>Send OTP</span>
  }
            </button>
          </>
        ) : (
          <>
            <p style={{ fontWeight: "bold", marginBottom: "0.5rem",textAlign:"center" }}>Please enter the verification code <br/>sent to {phoneNumber}</p>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
              <OtpInput
                  value={otp}
                  onChange={handleInputChange}
                  numInputs={4}
                  inputType="number"
                  renderSeparator={<span>&nbsp;</span>}
                  containerStyle={{ width: "350px", justifyContent: "center" }} // Adjust the width as per your requirements
                  inputStyle={{ width: "50px", height: "50px", fontSize: "24px",border: "2px solid #082061",
                  borderRadius: "4px", }} // Adjust the width, height, and font size as per your requirements
                  renderInput={(props, index) => (
                    <input pattern="\d*" {...props} autoFocus={index === 0} />
                  )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthModal;
