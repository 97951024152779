import React from 'react'
import Card1 from './card1';
import './card.css'
import about1 from '../../assets/images/about_1.JPG'
import about2 from '../../assets/images/about_2.JPG'
import about3 from '../../assets/images/about_3.JPG'
import about4 from '../../assets/images/about_4.jpg'

const Card = () => {

    const cardData = [
        {
          image: about1,
          description: 'At GoBlu-EV, our story begins with a passion for sustainable transportation and a commitment to creating a greener future. We recognized the urgent need to address the carbon emissions produced by traditional transportation methods in Australia. ',
        },
        {
          image: about2,
          description: 'Our vision is simple yet powerful: to provide an eco-friendly, zero-carbon alternative that not only meets the needs of commuters but also contributes to a cleaner and healthier environment.',
        },
        {
          image: about3,
          description: 'With our fleet of 100% electric vehicles, we offer a sustainable ride experience that combines convenience, comfort, and environmental consciousness. ',
        },
        {
          image: about4,
          description: "By choosing GoBlu-EV, you're not only getting from point A to point B; you're actively reducing your carbon footprint and joining us in building a more sustainable world, one ride at a time.",
        },
        // Add more card data as needed
    ];


  return (
    <div>
        <div className="card-container-about">
        {cardData.map((card, index) => (
          <Card1
            key={index}
            // title={card.title}
            description={card.description}
            image={card.image}
            alignment={index % 2 === 0 ? 'right' : 'left'} // Alternating alignment
          />
        ))}
      </div>
    </div>
  )
}

export default Card