import "./App.css";
import Router from "./router";
import { BrowserRouter } from "react-router-dom";
import { StateProvider } from "./context/StateProvider";
import { initialState } from "./context/initalState";
import reducer from "./context/reducer";
import { LoadScript } from "@react-google-maps/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Analytics } from "@vercel/analytics/react";
import ReactGA from "react-ga4";
function App() {
  ReactGA.initialize([
    {
      trackingId: "G-QKVSZ3CYMR",
    },
    {
      trackingId: "G-1BYYH8MD5W",
    },
  ]);

  return (
    <>
      <BrowserRouter>
        <StateProvider initialState={initialState} reducer={reducer}>
          <LoadScript
            googleMapsApiKey={"AIzaSyDXLs1ZuKxVN6u4mh3wLsqb95HEEN3ne5Y"}
            libraries={["places"]}
          >
            <Router />
          </LoadScript>
          <ToastContainer position="top-center" />
        </StateProvider>
      </BrowserRouter>
      <Analytics />
    </>
  );
}

export default App;
