export const co2_calculation = (distance) => {
  // Calculate one-fourth of the distance
  const oneFourthDistance = distance * 0.243;

  // Fix the result to two decimal places
  const result = oneFourthDistance.toFixed(2);

  return result;
};

export const autocompleteOptions = {
  componentRestrictions: { country: "au" },
};

export const initLocation = {
  lat: -33.86,
  lng: 151.12,
};

export const convertToAmPm = (time) => {
  const [hours, minutes] = time.split(":");
  let period = "AM";

  let hour = parseInt(hours);
  if (hour === 0) {
    hour = 12;
  } else if (hour === 12) {
    period = "PM";
  } else if (hour > 12) {
    hour -= 12;
    period = "PM";
  }

  return `${hour}:${minutes} ${period}`;
};

export const convertTo24HourFormat = (time) => {
  const [timeStr, period] = time.split(" ");
  const [hours, minutes] = timeStr.split(":");
  let hour = parseInt(hours);

  if (period === "PM" && hour !== 12) {
    hour += 12;
  } else if (period === "AM" && hour === 12) {
    hour = 0;
  }

  return `${hour.toString().padStart(2, "0")}:${minutes}`;
};

export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
};
