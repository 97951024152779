import React from 'react'
import './About.css'
import whoarewe from '../../assets/images/whoarewe.png'
import google from '../../assets/images/googlef.png'
import reviews from '../../assets/images/reviews.png'
import car from '../../assets/images/about_car1.png'
import Card from './card'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'

const Aboutus = () => {
  return (
    <div>
      <div className="laptop">
        <div className="headera">
          <div className="headeraa">
            <div className="bodya">
              “Finding Inspirations in Every Turn”
            </div>
          </div>
        </div>
        <div className="whoarewe">
          <div className="whoareweimg">
            <img src={whoarewe} alt=""  height="469px"/>
          </div>
          <div className="whoarewebody">
            <div className="whoarewehead">Who are we?</div>
            <div className="whoareweheadbody">We are Australia's only 100% electric cab service startup, incepted with the motive of making the rides sustainable and environmentally responsible to create a better world. Transportation sector today contributes to 19% of Australia's emission. We are here to change the way we travel, making each ride eco-friendly and playing its role for a better future of the world.</div>
          </div>
        </div>
        <div className="theyloveus">
          <div className="theyloveustext">
            <div className="google1">
              <img src={google} alt="" height="35px" />
            </div>
            <div className="fivestarx1">
              <i className='bi bi-star-fill'></i>
              <i className='bi bi-star-fill'></i>
              <i className='bi bi-star-fill'></i>
              <i className='bi bi-star-fill'></i>
              <i className='bi bi-star-fill'></i>
            </div>
            <div className="theyloveustext1">
              They Love Us!
            </div>
            <div className="theyloveustext2">
              5 Star Rating on Google. Choose your rides wisely
            </div>
          </div>
          <div className="reviews">
            <img src={reviews} alt="" height="480px"/>
          </div>
        </div>
        <div className="ourstory">
          <div className="ourstoryhead">Our Story</div>
          <div className="ourstorytitle">let’s dive into the problem we are solving </div>
          <Card></Card>
        </div>
        <div className="kickstartx">
            <p className=' text-center fs-4 fw-medium text-white'>
                "Kick-start your eco-conscious journey with us – <br />
                your first step towards greener rides."
            </p>
           <Link to="/book">
           <button>
                BOOK NOW
            </button></Link>
        </div>
      </div>
      <div className="mobile">
        <div className="finding-inspiration">
          <div className="inspiration-text">
              “Finding Inspirations in Every Turn”
          </div>
        </div>
        <div className="about-details">
          <div className="details-car">
            <div className="details-car-back">
              <div className="details-car-text">
                We are Australia's only 100% electric cab service startup, incepted with the motive of making the rides sustainable and environmentally responsible to create a better world.Transportation sector today contributes to 19% of Australia's emission. We are here to change the way we travel, making each ride eco-friendly and playing its role for a better future of the world.
              </div>
            </div>
          </div>
          <div className="star-google">
              <div className="fivestarx2">
                <i className='bi bi-star-fill'></i>
                <i className='bi bi-star-fill'></i>
                <i className='bi bi-star-fill'></i>
                <i className='bi bi-star-fill'></i>
                <i className='bi bi-star-fill'></i>
              </div>
              <div className="rating-google">
                They love Us! 5 star rating on Google
              </div>
          </div>
          <div className="our-story">
            <div className="our-story-body">
              <div className="our-story-head">
                Our Story
              </div>
              <div className="our-story-text">
                At GoBlu-EV, our story begins with a passion for sustainable transportation and a commitment to creating a greener future. We recognized the urgent need to address the carbon emissions produced by traditional transportation methods in Australia. Fueled by this awareness, we embarked on a journey to revolutionize the way people travel. <br /><br />
                Our vision is simple yet powerful: to provide an eco-friendly, zero-carbon alternative that not only meets the needs of commuters but also contributes to a cleaner and healthier environment. With our fleet of 100% electric vehicles, we offer a sustainable experience that combines convenience, comfort, and environmental consciousness. By choosing GoBlu-EV, you're not only getting from point A to point B; you're actively reducing your carbon footprint and joining us in building a more sustainable world, one ride at a time."
              </div>
            </div>
            <div className="our-story-img">
              {/* <img src={car} alt=""/> */}
            </div>
          </div>

          
        </div>
        <div className="kickstartx">
            <p className=' text-center fs-4 fw-medium text-white'>
                "Kick-start your eco-conscious journey with us – <br />
                your first step towards greener rides."
            </p>
            <Link to="/book">
           <button>
                BOOK NOW
            </button></Link>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Aboutus