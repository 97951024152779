import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../../context/StateProvider";
import {
  addPickupNote,
  bookingInfo,
  fetchSessionUrl
} from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import Loader from "../../../loader/loader";
import { toast } from "react-toastify";
import "./Confirm.css";
import leaf from '../../../../assets/images/leaf.png';
import { Button, Modal } from "react-bootstrap"; 
import check from './checks.json'
import va from "@vercel/analytics";
import APPDownload from "../../../layout/app";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";
export default function BookingConfirm() {
  const [booking, setBooking] = useState(null);
  const [{ token }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(true); 
  const [showModal, setShowModal] = useState(false); 
  const [pickupNote, setPickupNote] = useState(""); 
  const [showAppModal, setShowAppModal] = useState(false);

  const navigate = useNavigate();


  const handleOpenAppModal = () => {
    setShowAppModal(true);
  };

  const handleCloseAppModal = () => {
    setShowAppModal(false);
  };

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Australia/Melbourne",
  };

  const dateFormatter = (dateString) => {
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      new Date(dateString)
    );
    return formattedDate;
  };

  const roundToTwoDecimal = (number) => {
    return Math.round(number * 100) / 100;
  };
  const getBokingInfo = async () => {
    va.track("slot_booked");
    try {
      // dispatch({ type: "SET_LOADING", isLoading: true });

 
      const response = await bookingInfo(
        localStorage.getItem("booking") ,
        token
      );
      if (response.status === 200) {
        setBooking(response.data.results[0]);
        ReactPixel.trackSingleCustom('754277743000300', "booking confirm",{booking:"confirm"});
        ReactGA.event({
          category: "booking confirmation",
          action: "booking",
        });
        handleOpenAppModal()
      }
    } catch {
      toast.error("Failed to fetch fare");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("booking")) {
      navigate("/book");
    }
    getBokingInfo();
  }, []);
  const handleShowModal = () => {
    setShowModal(true);
  };


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmitPickupNote = async () => {
    try {
      // console.log(token)
      const response = await addPickupNote(
        {note:pickupNote},
        token,
        localStorage.getItem("booking")
      );
      if (response.status === 200) {
        setShowModal(false);
        toast.success("Pickup note submitted successfully!");
      }else{
        toast.error("Failed to submit pickup note");
      }

    } catch (error) {
      toast.error("Failed to submit pickup note");
    }
  };
  return (
   <>
        {/* outer layer */}
{
  isLoading ? <Loader/> :<div className='d-flex justify-content-center'>
  <div className='confirm__outer'>
              {/*  header*/}
  
  <div className='confirm__header ' style={{textAlign:"center"}}>
  <p   style={{
      color: '#FCFCFC',
      fontSize: '30px',
      fontFamily: 'Red Hat Display',
      fontWeight: 700,
      wordWrap: 'break-word',
    }}>Congratulations       {booking?.riderName.split(" ")[0].charAt(0).toUpperCase() +
    booking?.riderName.split(" ")[0].slice(1)}{" "} </p>
  <p   style={{
      color: '#FCFCFC',
      fontSize: '14px',
      fontFamily: 'Red Hat Display',
      fontWeight: 600,
      wordWrap: 'break-word',
    }}>Your Goblu-EV ride is booked successfully</p>
  </div>
  
  <center>
  <Lottie style={{width:"70px",height:"70px"}} animationData={check} loop={false} />
    {/* <img width={50} height={50} alt="success_logo" className='mt-5' src={require('../../../../assets/images/verify.svg').default} /> */}
  <br/>
  <h4 className="mt-3">Booking confirmed </h4>
  </center>
  
  {/* lower container */}
  <div className='container'  style={{paddingTop:"20px"}}>
                  {/* booking tim */}
                  <div
    style={{
      textAlign: 'left',
      color: '#A1A1A1',
      fontSize: '16px',
      fontFamily: 'Red Hat Display',
      fontWeight: 600,
      lineHeight: '29.57px',
      wordWrap: 'break-word',
    }}
  >
    Booked on:    {booking && dateFormatter(booking?.requestedTimestamp)}
  </div>
              {/* location  */}
              <div className="location w-100  d-flex justify-content-start  align-items-center gap-4 pt-3">
          <div className="icons d-flex">
            <div className="borderone">
              <div className="circleone"></div>
            </div>
            <div className="line"></div>
            <div className="bordertwo">
              <div className="circletwo"></div>
            </div>
          </div>
          <div className="places">
            <p  style={{textAlign:'left'}}>{booking?.request.startLocation}</p>
            <div className="horizontal-line"></div>
            <p style={{textAlign:'left'}}>{booking?.request.endLocation}</p>
          </div>
        </div>
              {/* ride time */}
  
                  {/* co2 saved */}
                  <div className="fare-details d-flex w-100 justify-content-between mt-4">
          <div className="fare-pickup">
            <p className=' m-0'>Pickup:</p>
            <p className=' m-0'>{booking && dateFormatter(booking?.expectedStartTime)}</p>
          </div>
          <div className="fare">
            <p className=' m-0'> {booking.paymentMode==="online"? "Prepaid by card":"Fixed Fare"}:</p>
            <p className=' m-0'>A$ {booking && booking.totalFare}</p>
          </div>
        </div>
        <div className="co-info w-100 d-flex justify-content-center align-items-center p-3 gap-2 mt-4">
          <img src={leaf} alt="leaf__icon" />
          <p className=' m-0 fw-medium'>You will save {booking && roundToTwoDecimal(booking?.co2Saving)}kg Co2 with this ride</p>
        </div>
        <div className="about-ride d-flex justify-content-center align-items-cente w-100 mt-3">
       <p className='align-items-center d-flex m-0'> <img width={15} height={15} src={require('../../../../assets/images/information.svg').default} alt="leaf__icon" /> Ride details will be provided 30 mins before the ride. Pay directly to the driver.</p>
        </div>
<div className="fare-details">
<div className="fare">
          <p></p>
        <p onClick={handleOpenAppModal} style={{ cursor: 'pointer',textAlign:"center" }}>
        Click here to download our app and track your rides!
      </p>
        </div>
</div>


        <center>
        <button className="book mt-4" onClick={handleShowModal}>
        Add Pickup Note/ Flight Details
      </button>
       <button className="book mt-4" onClick={()=>{
        navigate("/book/rides")
       }}>
                     View Dashboard
              </button>
       </center>
          </div>
  </div>
  </div>
  
}

{/* modal for app download */}

<APPDownload show={showAppModal} handleClose={handleCloseAppModal}  />

{/* Pickup Note Modal */}
<Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Pickup Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows="4"
            placeholder="e.g. flight detalis"
            value={pickupNote}
            onChange={(e) => setPickupNote(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmitPickupNote}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
   </>
  )
}
