import React from 'react';
import { FaStar } from 'react-icons/fa';

const StarRating = ({ rating }) => {
  const maxStars = 5;
  const stars = [];

  for (let i = 0; i < maxStars; i++) {
    const starClass = i < rating ? 'star-filled' : 'star-empty';
    stars.push(
      <FaStar
        key={i}
        className={`star-icon ${starClass} `}
        color={i < rating ? 'gold' : 'gray'}
      />
    );
  }

  return <div className="star-rating" style={{marginLeft:"15px"}}>{stars}</div>;
};

export default StarRating;