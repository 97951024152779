import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineInfoCircle } from 'react-icons/ai';
import { updateUser } from '../../../../services/api';
import { HiUser } from 'react-icons/hi';
import { toast } from 'react-toastify';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
const modalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  zIndex:1000
};

const containerStyle = {
  background: 'white',
  maxWidth: '360px',
  padding: '2rem',
  borderRadius: '0.5rem',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  position: 'relative',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '1rem',
  right: '1rem',
  color: '#666',
  cursor: 'pointer',
};

const ProfileModal = ({ closeModal, book, token, dispatch,isBooking,addCardModalOpen,selectedPaymentMode }) => {
  const [name, setName] = useState('');

  const handleCreateProfile = async () => {
    ReactGA.event({
      category: "auth",
      action: "profile_create",
    });
    ReactPixel.trackSingleCustom('754277743000300', "profile_create_goblu",{booking:"profile_create_goblu"});
    // Validate name
    if (name.trim() === '') {
      toast.error('Please enter your name.');
      return;
    }

    try {
      // Update user information on the server
      const response = await updateUser({ fullName: name }, token);
      if (response.status === 200) {
        const updatedUser = response.data;
        // Update user information in the state
        dispatch({ type: 'SET_USER', user: updatedUser });
        localStorage.setItem('user', JSON.stringify(updatedUser));
   try{
    if(localStorage.getItem("utmMedium")){
      updateUser({
        utmMedium: localStorage.getItem('utmMedium'),
        utmSource: localStorage.getItem('utmSource'),
        utmCampaign: localStorage.getItem('utmCampaign')
      }, token);
    }
   }catch(error){
    
   }

        await closeModal();
        if(isBooking && response.data.hasPaymentDetails){
          await book();
        }else if(isBooking && selectedPaymentMode==="online"){
          await  addCardModalOpen()

        }else{
          await book()
        }
       
        
      } else {
        toast.error('Failed to create profile. Please try again.');
      }
    } catch (error) {
      toast.error('Failed to create profile. Please try again.');
    }
  };

  return (
    <div style={modalStyle}>
      <div style={containerStyle}>
        {/* Close button */}
        <button style={closeButtonStyle} onClick={closeModal}>
          <AiOutlineClose size={24} />
        </button>
        {/* Modal content */}
        <div className="my-4">
          <label className="block font-bold text-gray-700">Enter your name:</label>
          <input
            type="text"
            style={{width:"100%"}}
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
            className="w-full px-4 py-2 mt-2 rounded border focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>

{
  isBooking ? <>
   {/* Info message */}
   <div className="flex items-center my-4">
          <AiOutlineInfoCircle size={20} className="text-blue-600 mr-2" />
          <p className="text-sm text-gray-600">
            We will send the confirmation SMS to your mobile number.
          </p>
        </div>

        {/* Confirm button */}
        <button
          style={{
            background: 'linear-gradient(to right, #0095dd, #004ea8)',
            color: 'white',
          }}
          className="w-full py-2 rounded hover:bg-blue-600"
          onClick={handleCreateProfile}
        >
          Confirm Your EV Ride
        </button>
  </> : <> <center>     <button
          style={{
            background: 'linear-gradient(to right, #0095dd, #004ea8)',
            color: 'white',
          }}
          className="w-full py-2 rounded hover:bg-blue-600"
          onClick={handleCreateProfile}
        >
          Confirm
        </button> </center></>
}
       
      </div>
    </div>
  );
};

export default ProfileModal;
