import "./service.css";
import React, { useState } from 'react';
import rides from '../assets/images/rides.png';
import airportRides from '../assets/images/airport-rides.png'
import anyRides from '../assets/images/a2a.jpeg'
import reactangle from '../assets/images/rectangle-11.svg'


const Serviceable_regions = () => {
  const Rides = () =>{
    return (
      <>
      <img className="rides-img" height={400} width={500} src={anyRides}></img>
      {/* <div className= "Pickup-Drop-1"> Pickup and Drop </div> */}
      </>
    )
  };
  const AirportTransfers = () =>{
    return (
      <>
      <img className="rides-img" src={airportRides}></img>
      </>
    )
  };
  const Tabs = ({config}) => {
    const [activeTab, setActiveTab] = useState(0);
    return (
      <div className="tab">
        <div className="tab-headers">
          {
            config.map( (entry, index) => (
              <div className={`tab-header ${activeTab === index ? "active" : ""}`} 
              onClick={() => setActiveTab(index)}>
                {entry.header}
              </div>
            ))
          }
        </div>
        <div className="tab-body">
          {config[activeTab].component}
        </div>
      </div>
    )
  };
  return (
    <div className="iphone-13-14-1">
      <div className="vector-parent">
        <img className="frame-child" alt="" src="/arrow-1.svg" />
        <div className="serviceable-regions">
          <span className="serviceable">Serviceable regions</span>
        </div>
      </div>
      <Tabs config = {[
       
        {header:"Airport Transfer", component:<AirportTransfers/>},
        {header:"City Rides", component:<Rides  />},
      ]}/>
      {/* <div className="screenshot-2023-10-07-at-1132"> 
      <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1gSnw1roDuao65juUl0yRSVlefqZIS30&ehbc=2E312F&noprof=1" width="100%" height="480"></iframe>
      </div> */}
      {/* <div className="parenID">
        <img className="iphone-13-14-1-child" alt="" src="/rectangle-1.svg" />
        <div className="pickup-and-drop">Pickup and Drop</div>
      </div> */}
      <div className="rectangle-parent">
        <div className="frame-item" />
        <div className="melbourne-vic">Melbourne-VIC</div>
      </div>
      <div className="vector-container">
        <img className="rectangle-icon" alt="" src={reactangle} />
        <div className="pickup-and-drop1">Pickup and Drop</div>
      </div>
    </div>
    
  );
};

export default Serviceable_regions;
