import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import playstore from '../../assets/images/playstore.png';
import applestore from '../../assets/images/applestore.png';

const APPDownload = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}  centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Download Our App</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>Unlock exclusive offers and discounts by downloading our app today!</p>
        <div className="app-store-images">
          <a href="https://play.google.com/store/apps/details?id=com.goblu.user" target="_blank" rel="noopener noreferrer">
            <img src={playstore} alt="Play Store" />
          </a>
          <a href="https://apps.apple.com/in/app/goblu-ev/id6464328871" target="_blank" rel="noopener noreferrer">
            <img src={applestore} alt="App Store" />
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
  <Button style={{background:"linear-gradient(to right, #0095dd, #004ea8)"}} onClick={handleClose}>
    Continue through web
  </Button>
</Modal.Footer>
    </Modal>
  );
};

export default APPDownload;
